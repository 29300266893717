/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients {
    .container{
        .clients-slider {
            .swiper-wrapper {
                .swiper-slide {
                    img {
                        // height: 7rem !important;
                        opacity: 0.6;
                        transition: 0.3s;
                        filter: grayscale(100);
                        &:hover {
                            opacity: 1;
                            filter: none;
                        }
                    }
                }
                .swiper-pagination {
                    margin-top: 20px;
                    position: relative;
                    .swiper-pagination-bullet {
                        width: 12px;
                        height: 12px;
                        background-color: rgba(255, 255, 255, 1);
                        opacity: 1;
                        border: 1px solid var(--oksijen-turuncu);
                    }
                    .swiper-pagination-bullet-active {
                        background-color: var(--oksijen-turuncu);
                    }
                }
            }
        }
    }
}
